<template>
	<div class="statisticsInfo_work">
		<div class="item" v-for="(item, index) in list" :key="index">
			<div class="item_content">
				<img :src="item.url" alt="" />
				<div class="line">
					<div class="title">{{ item.title }}</div>
					<span class="num">{{ item.num }}</span>
				</div>
			</div>
			<template v-if="item.lineTip && item.lineTip.length > 0">
				<div class="lineTip" v-for="(child, index) in item.lineTip" :key="index" :style="item.otherStyle">
					<div class="tip" v-for="(childItem, index) in child" :key="index">
						<div class="dot" :style="{ background: childItem.bg }"></div>
						<span class="text">{{ childItem.name }}</span>
						<span class="num">{{ childItem.num }}</span>
					</div>
					<!-- <div class="tip">
						<div class="dot" :style="{ background: '#FF5C4C' }"></div>
						<span>待建设</span>
						<span class="num">3984</span>
					</div> -->
				</div>
			</template>
		</div>
	</div>
</template>

<script>
export default {
	// 养老服务监管--信息统计
	name: 'statisticsInfo_work',
	data() {
		return {}
	},
	props: {
		list: {
			type: Array,
			default: () => {
				return []
			},
		},
	},
}
</script>

<style lang="scss" scoped>
.statisticsInfo_work {
	display: flex;
	flex-wrap: wrap;
	.item {
		min-width: 1.25rem /* 240/192 */;
		height: 0.78125rem /* 150/192 */;
		background: #ffffff;
		border-radius: 0.03125rem /* 6/192 */;
		padding-top: 0.130208rem /* 25/192 */;
		margin-bottom: 0.130208rem /* 25/192 */;
		box-sizing: border-box;
		margin-right: 0.104167rem /* 20/192 */;
		.item_content{
			display: flex;
			align-items: center;
			box-sizing: border-box;
			padding: 0.1rem;
			img {
				width: 0.3125rem /* 60/192 */;
				height: 0.3125rem /* 60/192 */;
			}
			.line {
				display: flex;
				flex-direction: column;
				justify-content: center;
				box-sizing: border-box;
				padding: 0 0.15625rem 0 0.10416rem /* 30/192 */;
				.title {
					font-size: 0.104167rem /* 20/192 */;
					text-align: center;
					color: #999999FF;
				}
				.num {
					flex: 1;
					font-family: DIN;
					font-size: 0.244791rem /* 47/192 */;
					font-weight: bold;
					color: #333333FF;
					text-align: center;
					
				}
			}
		}
		
		
		.lineTip {
			display: flex;
			justify-content: space-between;
			padding: 0 0.104167rem /* 20/192 */;
			margin-top: 0.0625rem /* 12/192 */;
			.tip {
				display: flex;
				align-items: center;
				.dot {
					width: 0.0625rem /* 12/192 */;
					height: 0.0625rem /* 12/192 */;
					border-radius: 0.03125rem /* 6/192 */;
				}
				span {
					font-size: 0.072917rem /* 14/192 */;
					color: #666666;
					margin-left: 4px;
				}
				.text {
					min-width: 56px;
				}
				.num {
					font-family: DIN;
					font-size: 0.083333rem /* 16/192 */;
				}
			}
		}
	}
}
</style>
